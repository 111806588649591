<template>
<div>
  <nav-bar></nav-bar>
  <div class="container-fluid page-body-wrapper">
  <side-bar></side-bar>
  <!-- partial -->
  <div class="main-panel">
    <div class="content-wrapper">
    <div class="page-header">
      <h4 class="page-title">Statistics</h4>
    </div>

    <div class="mt-5">
        <div class="page-header">
            <div class="row align-items-center gap-1"> <!-- Align items vertically centered and add gap between columns -->
                <div class="col-auto text-center text-md-end"> <!-- Use col-auto to fit the content size -->
                    <i class="mdi mdi-account-multiple mdi-24px"></i>
                </div>
                <div class="col text-center text-md-start"> <!-- Use col to make it take the remaining space -->
                    <h4 class="page-title">Patients & OPD</h4>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-3 mb-lg-0 mb-2">
            <div class="card">
                <div class="card-body" @click="openNextPage(1, -1)">
                    <div class="row align-items-center"> <!-- Aligns items vertically centered -->
                        <div class="col-2 col-md-2 text-center text-md-end"> <!-- Center on small screens, right on medium and larger -->
                            <i class="mdi mdi-account-multiple mdi-36px"></i>
                        </div>
                        <div class="col-10 col-md-8 mt-2"> <!-- Adjusts column size for smaller screens -->
                            <h4>Patients</h4>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 mt-1 text-center text-md-start"> <!-- Center text on small screens -->
                            <h5><small class="text-secondary">View and export patients statistics data.</small></h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-3 mb-lg-0 mb-2">
            <div class="card">
                <div class="card-body" @click="openNextPage(2, -1)">
                    <div class="row align-items-center"> <!-- Align items vertically centered -->
                        <div class="col-2 col-md-2 text-center text-md-end"> <!-- Center on small screens, right on medium and larger -->
                            <i class="mdi mdi-book-multiple mdi-36px"></i>
                        </div>
                        <div class="col-10 col-md-8 mt-2"> <!-- Adjusts column size for smaller screens -->
                            <h4>OPDs</h4>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 mt-1 text-center text-md-start"> <!-- Center text on small screens -->
                            <h5><small class="text-secondary">View and export patients OPDs statistics data.</small></h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-3 mb-lg-0 mb-2">
            <div class="card">
                <div class="card-body" @click="openNextPage(4, -1)">
                    <div class="row align-items-center"> <!-- Align items vertically centered -->
                        <div class="col-2 col-md-2 text-center text-md-end"> <!-- Center on small screens, right on medium and larger -->
                            <i class="mdi mdi-book-plus mdi-36px menu-icon"></i>
                        </div>
                        <div class="col-10 col-md-8 mt-2"> <!-- Adjusts column size for smaller screens -->
                            <h4>Appointments</h4>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 mt-1 text-center text-md-start"> <!-- Center text on small screens -->
                            <h5><small class="text-secondary">View and export appointments statistics data.</small></h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-3 mb-lg-0 mb-2">
            <div class="card">
                <div class="card-body" @click="openNextPage(6, -1)">
                    <div class="row align-items-center"> <!-- Align items vertically centered -->
                        <div class="col-2 col-md-2 text-center text-md-end"> <!-- Center on small screens, right on medium and larger -->
                            <i class="mdi mdi-book-open-page-variant mdi-36px"></i>
                        </div>
                        <div class="col-10 col-md-8 mt-2"> <!-- Adjusts column size for smaller screens -->
                            <h4>IPDs</h4>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 mt-1 text-center text-md-start"> <!-- Center text on small screens -->
                            <h5><small class="text-secondary">View and export patients IPDs statistics data.</small></h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="mt-5">
        <div class="page-header">
            <div class="row align-items-center gap-1"> <!-- Align items vertically centered and add gap between columns -->
                <div class="col-auto text-center text-md-end"> <!-- Use col-auto to fit the content size -->
                    <i class="mdi mdi-cash-multiple mdi-24px"></i>
                </div>
                <div class="col text-center text-md-start"> <!-- Use col to make it take the remaining space -->
                    <h4 class="page-title">Earnings</h4>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-3 mb-lg-0 mb-2">
            <div class="card">
                <div class="card-body" @click="openNextPage(5, 'All')">
                    <div class="row align-items-center"> <!-- Align items vertically centered -->
                        <div class="col-2 col-md-2 text-center text-md-end"> <!-- Center on small screens, right on medium and larger -->
                            <i class="mdi mdi-cash-multiple mdi-36px float-right"></i>
                        </div>
                        <div class="col-10 col-md-8 mt-2"> <!-- Adjusts column size for smaller screens -->
                            <h4>All Earnings</h4>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 mt-1 text-center text-md-start"> <!-- Center text on small screens -->
                            <h5><small class="text-secondary">View and export all earnings statistics data.</small></h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-3 mb-lg-0 mb-2">
            <div class="card">
                <div class="card-body" @click="openNextPage(5, 1)">
                    <div class="row align-items-center"> <!-- Align items vertically centered -->
                        <div class="col-2 col-md-2 text-center text-md-end"> <!-- Center on small screens, right on medium and larger -->
                            <i class="mdi mdi-cash-multiple mdi-36px float-right"></i>
                        </div>
                        <div class="col-10 col-md-8 mt-2"> <!-- Adjusts column size for smaller screens -->
                            <h4>OPDs Earnings</h4>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 mt-1 text-center text-md-start"> <!-- Center text on small screens -->
                            <h5><small class="text-secondary">View and export opds earnings statistics data.</small></h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-3 mb-lg-0 mb-2">
            <div class="card">
                <div class="card-body" @click="openNextPage(5, 2)">
                    <div class="row align-items-center"> <!-- Align items vertically centered -->
                        <div class="col-2 col-md-2 text-center text-md-end"> <!-- Center on small screens, right on medium and larger -->
                            <i class="mdi mdi-cash-multiple mdi-36px float-right"></i>
                        </div>
                        <div class="col-10 col-md-8 mt-2"> <!-- Adjusts column size for smaller screens -->
                            <h4>IPDs Earnings</h4>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 mt-1 text-center text-md-start"> <!-- Center text on small screens -->
                            <h5><small class="text-secondary">View and export ipds earnings statistics data.</small></h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-3 mb-lg-0 mb-2">
            <div class="card">
                <div class="card-body" @click="openNextPage(5, 3)">
                    <div class="row align-items-center"> <!-- Align items vertically centered -->
                        <div class="col-2 col-md-2 text-center text-md-end"> <!-- Center on small screens, right on medium and larger -->
                            <i class="mdi mdi-cash-multiple mdi-36px float-right"></i>
                        </div>
                        <div class="col-10 col-md-8 mt-2"> <!-- Adjusts column size for smaller screens -->
                            <h4>Reports Earnings</h4>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 mt-1 text-center text-md-start"> <!-- Center text on small screens -->
                            <h5><small class="text-secondary">View and export reports earnings statistics data.</small></h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="mt-lg-3">
        <div class="col-md-3 mb-lg-0 mb-2">
            <div class="card">
                <div class="card-body" @click="openNextPage(5, 4)">
                    <div class="row align-items-center"> <!-- Align items vertically centered -->
                        <div class="col-2 col-md-2 text-center text-md-end"> <!-- Center on small screens, right on medium and larger -->
                            <i class="mdi mdi-cash-multiple mdi-36px float-right"></i>
                        </div>
                        <div class="col-10 col-md-8 mt-2"> <!-- Adjusts column size for smaller screens -->
                            <h4>Medicines Earnings</h4>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 mt-1 text-center text-md-start"> <!-- Center text on small screens -->
                            <h5><small class="text-secondary">View and export medicines earnings statistics data.</small></h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    </div>
    <Footer/>
  </div>
</div>
</div>
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import { mapActions } from "vuex";
import moment from "moment";
import DateRangePicker from 'vue2-daterange-picker'
import string from "../../constant/strings.js"
import Multiselect from 'vue-multiselect';

export default {
  name:'appointment',
    title: string.PAGE_TITLE_STATISTICS,
  components: {
      NavBar,
Footer,
      SideBar,
      DateRangePicker,
      Multiselect,
  },
  data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
	const maxDate = new Date(today)
	
	return {
      reports:[],
      moment: moment,
      is_API_call_running : false,
      reportType : 1,
      listPages:0,
      activePage:1,
      picker: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      max: maxDate,
      errorMsg:'',
      errorMessage : 'No Data Found.',
      selected_doctor : '',
      selected_department : '',
      departments : [],
      doctors : [],
      dateRange:{
        startDate: today,
        endDate: today
      },
    }
  },

  mounted() {

  },
  methods:{
    ...mapActions("hospital",[]),

    openNextPage(report_type, earning_type) {
        this.$router.push({ name: 'statistics', params:{'type':report_type, 'earning_type':earning_type}});
    }

  }
}
</script>
<style src="vue2-daterange-picker/dist/vue2-daterange-picker.css"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
